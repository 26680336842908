<template>
  <v-scale-transition>
    <wide-panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar flat>
        <v-menu
          v-model="monthMenu"
          :close-on-content-click="false"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="month"
              prepend-icon="mdi-calendar-outline"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="month"
            min="2020-11"
            :max="thisMonth"
            type="month"
            no-title
            @input="monthMenu = false"
          />
        </v-menu>
        <v-spacer />
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="ad"
        :loading="loading"
        item-key="dealer"
        class="elevation-1"
      >
        <template #items="props">
          <tr>
            <td class="text-xs-left">
              {{ props.item.dealer }}
            </td>
            <td class="text-xs-left">
              {{ props.item.name }}
            </td>
            <td class="text-xs-center">
              {{ props.item.entitledTier }}
            </td>
            <td class="text-xs-center">
              {{ props.item.blackGroupActivation }}
            </td>
            <td class="text-xs-center">
              {{ props.item.prepaidGroupActivation }}
            </td>
            <td class="text-xs-center">
              {{ props.item.groupDealerRecruitment }}
            </td>
            <td class="text-xs-center">
              {{ props.item.tier1ActiveDealer }}
            </td>
            <td class="text-xs-center">
              {{ props.item.personalActivation }}
            </td>
            <td class="text-xs-center">
              {{ props.item.personalErechargeSales }}
            </td>
            <td class="text-xs-center">
              {{ props.item.groupErechargeSales }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </wide-panel>
  </v-scale-transition>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'

const title = 'AD Achievement'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
        },
        {
          text: title, disabled: true,
        },
      ],
      month: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL'),
      thisMonth: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL'),
      monthMenu: false,
      loading: false,
      headers: [],
      ad: [],
      title,
    }
  },
  created: function () {
    this.getAdAchievement(this.month)
  },
  watch: {
    month: function (month) {
      this.getAdAchievement(month)
    },
  },
  methods: {
    getAdAchievement: function (month) {
      const param = createGetParams({ month })
      this.loading = true
      this.$rest.get('getAdAchievement.php', param)
        .then(response => {
          this.headers = response.data.headers
          this.ad = response.data.ad
        }).catch(e => {
          // ignore
        }).finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
